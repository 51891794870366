import React, { useState } from 'react';
import './login.css';
import firebase from "../firebase"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const auth = firebase.auth();
    auth.signInWithEmailAndPassword(email, password)
      .then(user => window.location.href = "/allTopics")
      .catch(e => alert("Invalid credentials"));
  };

  return (
    <div className="login-page">
      <div className="login-background"></div>
      <div className="login-container">
        <div className="brand-section">
          <div className="logo">BUET EDU</div>
          <p className="brand-tagline">Admin Dashboard</p>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Welcome Back</h2>
          <p className="login-subtitle">Please sign in to continue</p>
          
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-envelope"></i>
              <input
              style={{"marginLeft":"20px", "width":"90%"}}
                type="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
          </div>
          
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-lock"></i>
              <input
              style={{"marginLeft":"20px", "width":"90%"}}

              type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                <i  style={{

"marginLeft": "-19px",
"marginTop": "25px"
                }} className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
              </button>
            </div>
          </div>
          
          <button type="submit" className="login-button">
            Sign In
            <i className="fas fa-arrow-right"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
