import axios from "axios"
import React, { Component } from "react"
import { keys } from "../keys"
import { fetchTopics } from "../actions/topicAction"
import Topic from "./Edit/Topic"
import { connect } from "react-redux"
import Navbar from "./Navbar"
import { link } from "../base_url"
import "./allTopics.css"

class AllTopics extends Component {
  state = {
    name: "",
    subject: "",
    description: "",
    logo: "",
    islive: "0",
    nseries: "",
    lang: "",
    serial: "",
    nproblem: "",
    level_title: "",
    level_des: "",
    level: "",
    topics: [],
    tempTopics: [],
    currentTopic: null,
    lang: 'en',
    isSubmitting: false,
    isLoadingTopics: false,
    isAddingLevel: false,
    isAssigningLevel: {},
    isUpdatingProblems: {},
    toast: {
      show: false,
      message: '',
      type: 'success' // success, error, info
    }
  }

  showToast = (message, type = 'success') => {
    this.setState({
      toast: {
        show: true,
        message,
        type
      }
    });
    // Hide toast after 3 seconds
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          message: '',
          type: 'success'
        }
      });
    }, 3000);
  }

  getLang = (e) => {
    this.setState({
      topics: []
    })
    if (e.target.checked) {
      this.setState({ lang: 'bn' });
    } else {
      console.log('en');
      this.setState({ lang: 'en' });
    }
  }

  getTopics = (slug) => {

  }

  componentDidMount = () => {
    //if(this.state.lang=='en' || this.state.lang="")
    //this.getTopics('en');
  }

  setTopics = () => {
    this.setState({
      topics: this.tempTopics
    })
  }

  setCurrentTopicToNull = () => {
    this.setState({ currentTopic: null })
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  assignLevel = async (id) => {
    if (this.state.level != "") {
      this.setState(prev => ({ isAssigningLevel: { ...prev.isAssigningLevel, [id]: true } }));
      const temp = {
        "topic_id": id,
        "level_id": this.state.level
      }
      try {
        await axios({
          method: 'post',
          url: link.url + '/admin/topic/assignLevel',
          data: temp,
          headers: {
            'authorization': keys.authorization,
          }
        });
        alert("Level assigned successfully!");
      } catch (e) {
        console.log(e);
        alert("Failed to assign level", "error");
      }
      this.setState(prev => ({ isAssigningLevel: { ...prev.isAssigningLevel, [id]: false } }));
    } else {
      alert("Please enter a level ID", "error");
    }
  }

  loadTopics = async () => {
    this.setState({ isLoadingTopics: true });
    try {
      const res = await axios.get(link.url + 'topics/' + this.state.lang, {
        headers: {
          'authorization': keys.authorization,
        }
      });
      this.setState({
        topics: res.data,
      });
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoadingTopics: false });
  }

  submit = async () => {
    this.setState({ isSubmitting: true });
    let temp = {};
    temp["topic"] = this.state;
    try {
      await axios({
        method: 'post',
        url: link.url + '/admin/addTopic',
        data: temp,
        headers: {
          'authorization': keys.authorization,
        }
      });
      alert("Topic added successfully!");
    } catch (e) {
      console.log(e);
      alert("Failed to add topic", "error");
    }
    this.setState({ isSubmitting: false });
  }

  submitNProblem = async (id) => {
    this.setState(prev => ({ isUpdatingProblems: { ...prev.isUpdatingProblems, [id]: true } }));
    let temp = {
      topic_id: id,
      nproblem: this.state.nproblem
    }
    try {
      await axios({
        method: 'post',
        url: link.url + 'admin/editTopicProblemCount',
        data: temp,
        headers: {
          'authorization': keys.authorization,
        }
      });
      alert("Successfully edited nproblem");
    } catch (e) {
      console.log(e);
      alert("Failed to update problems", "error");
    }
    this.setState(prev => ({ isUpdatingProblems: { ...prev.isUpdatingProblems, [id]: false } }));
  }

  levelData = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitLevelData = async () => {
    this.setState({ isAddingLevel: true });
    const temp = {
      "title": this.state.level_title,
      "description": this.state.level_des
    }
    try {
      await axios({
        method: 'post',
        url: link.url + '/admin/addLevel',
        data: temp,
        headers: {
          'authorization': keys.authorization,
        }
      });
      alert("Level added successfully!");
    } catch (e) {
      console.log(e);
      alert("Failed to add level", "error");
    }
    this.setState({ isAddingLevel: false });
  }

  searchText = (text) => {
    let temp = []
    this.props.alltopics.forEach(e => {
      if (e.name.toLowerCase().includes(text.toLowerCase())) {
        temp.push(e);
      }
      this.setState({
        topics: temp
      })
    })
  }

  handleIsLiveToggle = (e) => {
    this.setState({
      islive: e.target.checked ? "1" : "0"
    });
  }

  render() {
    return (
      <div className="dashboard-container">
        {/* Toast notification component */}
        {this.state.toast.show && (
          <div className={`toast-notification ${this.state.toast.type}`}>
            <div className="toast-message">
              {this.state.toast.type === 'success' && <i className="fas fa-check-circle"></i>}
              {this.state.toast.type === 'error' && <i className="fas fa-exclamation-circle"></i>}
              {this.state.toast.type === 'info' && <i className="fas fa-info-circle"></i>}
              <span>{this.state.toast.message}</span>
            </div>
          </div>
        )}
        <Navbar fun={this.searchText} />
        <div className="dashboard-content">
          <div className="action-panel">
            <div className="main-actions">
              <button
                className="action-button primary"
                data-toggle="modal"
                data-target="#exampleModalLong"
              >
                <i className="fas fa-plus-circle"></i>
                Add Topic
              </button>
              <button
                className="action-button secondary"
                data-toggle="modal"
                data-target="#exampleModalLong2"
              >
                <i className="fas fa-layer-group"></i>
                Add Level
              </button>
              <button
                onClick={this.loadTopics}
                className="action-button info"
                disabled={this.state.isLoadingTopics}
              >
                {this.state.isLoadingTopics ? (
                  <span><i className="fas fa-spinner fa-spin"></i> Loading topics...</span>
                ) : (
                  <><i className="fas fa-sync"></i> Refresh Topics</>
                )}
              </button>
            </div>
            <div className="language-toggle">
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  id="language-toggle"
                  onChange={this.getLang}
                />
                <label htmlFor="language-toggle">
                  <span className="toggle-track"></span>
                  <span className="toggle-indicator"></span>
                  <span className="toggle-labels">
                    {/* <span style={{color:"black"}} className="toggle-label-en">EN</span> */}
                    <span style={{color:"black",marginLeft:"50px"}} className="toggle-label-bn">BN</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="topics-grid">
            {this.state.topics && this.state.topics.map(topic => (
              <div key={topic.topic_id} className="topic-card">
                <div className="topic-card-header">
                  <img
                    src={topic.logo}
                    className="topic-logo"
                    alt={topic.name}
                  />
                  <a href={'/topics/' + topic.topic_id} className="topic-title">
                    <h3>{topic.name} <span className="lang-indicator">({this.state.lang})</span></h3>
                  </a>
                </div>
                <div className="topic-card-body">
                  <p className="topic-description">{topic.description}</p>
                  <div className="topic-actions">
                    <button
                      className="topic-btn details"
                      data-toggle="collapse"
                      data-target={'#collapse' + topic.topic_id}
                    >
                      <i className="fas fa-info-circle"></i> Details
                    </button>
                    <button
                      className="topic-btn edit"
                      onClick={() => this.setState({ currentTopic: topic })}
                      data-toggle="modal"
                      data-target="#editTopic"
                    >
                      <i className="fas fa-edit"></i> Edit
                    </button>
                    <button
                      className="topic-btn level"
                      data-toggle="collapse"
                      data-target={'#collapse2' + topic.topic_id}
                    >
                      <i className="fas fa-layer-group"></i> Add Level
                    </button>
                    <button
                      className="topic-btn problems"
                      data-toggle="collapse"
                      data-target={'#collapse3' + topic.topic_id}
                    >
                      <i className="fas fa-tasks"></i> Problems
                    </button>
                  </div>
                  <div className="collapse topic-details" id={'collapse' + topic.topic_id}>
                    <div className="details-grid">
                      <div className="detail-item">
                        <span className="detail-label">Problems:</span>
                        <span className="detail-value">{topic.nproblem}</span>
                      </div>
                      <div className="detail-item">
                        <span className="detail-label">Series:</span>
                        <span className="detail-value">{topic.nseries}</span>
                      </div>
                      <div className="detail-item">
                        <span className="detail-label">ID:</span>
                        <span className="detail-value">{topic.topic_id}</span>
                      </div>
                      <div className="detail-item">
                        <span className="detail-label">Serial:</span>
                        <span className="detail-value">{topic.serial}</span>
                      </div>
                    </div>
                  </div>
                  <div className="collapse" id={'collapse2' + topic.topic_id}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control modern-input editable-input"
                        placeholder="Enter Level ID"
                        name="level"
                        onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                        autoComplete="off"
                      />
                      <button
                        className="btn-submit"
                        onClick={() => this.assignLevel(topic.topic_id)}
                        disabled={this.state.isAssigningLevel[topic.topic_id]}
                      >
                        {this.state.isAssigningLevel[topic.topic_id] ? (
                          <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fas fa-layer-group"></i>
                        )}
                        {this.state.isAssigningLevel[topic.topic_id] ? 'Assigning...' : 'Assign Level'}
                      </button>
                    </div>
                  </div>
                  <div className="collapse" id={'collapse3' + topic.topic_id}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="problems-input"
                        placeholder="Enter number of problems"
                        name="nproblem"
                        onChange={(e) => this.setState({ nproblem: e.target.value })}
                        autoComplete="off"
                      />
                      <button
                        className="update-problems-btn"
                        onClick={() => this.submitNProblem(topic.topic_id)}
                        disabled={this.state.isUpdatingProblems[topic.topic_id]}
                      >
                        {this.state.isUpdatingProblems[topic.topic_id] ? (
                          <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                         null
                        )}
                        {this.state.isUpdatingProblems[topic.topic_id] ? 'Updating...' : 'Update Problems'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Add Topic Modal */}
        <div className="modal fade" id="exampleModalLong">
          <div className="modal-dialog">
            <div className="modal-content modern-modal">
              <div className="modal-header">
                <h5 className="modal-title">Add Topic</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Title"
                    name="name"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Subject"
                    name="subject"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control modern-input"
                    placeholder="Description"
                    name="description"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Logo URL"
                    name="logo"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <div className="toggle-switch-container">
                    <label className="toggle-switch-label">Is Live</label>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        id="islive-toggle"
                        onChange={this.handleIsLiveToggle}
                        checked={this.state.islive === "1"}
                      />
                      <label htmlFor="islive-toggle">
                        <span className="toggle-track"></span>
                        <span className="toggle-indicator"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Number of Series"
                    name="nseries"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Number of Problems"
                    name="nproblem"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Serial"
                    name="serial"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Language"
                    name="lang"
                    onChange={this.change}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control modern-input"
                    placeholder="Level Id"
                    name="level"
                    onChange={this.change}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-submit"
                  onClick={this.submit}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.isSubmitting ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-save"></i>
                  )}
                  {this.state.isSubmitting ? 'Saving...' : 'Save Topic'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Add Level Modal */}
        <div className="modal fade" id="exampleModalLong2">
          <div className="modal-dialog">
            <div className="modal-content modern-modal">
              <div className="modal-header">
                <h5 className="modal-title">Add New Level</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="modern-form">
                  <div className="form-group">
                    <label>Level Title</label>
                    <input type="text" name="level_title" onChange={this.levelData} />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea name="level_des" onChange={this.levelData}></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn-cancel" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn-submit" onClick={this.submitLevelData} disabled={this.state.isAddingLevel}>
                  {this.state.isAddingLevel ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-plus"></i>
                  )}
                  {this.state.isAddingLevel ? 'Creating...' : 'Create Level'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.currentTopic && (
          <Topic 
            topic={this.state.currentTopic} 
            setCurrentTopicToNull={this.setCurrentTopicToNull}
             // Add this line
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    topics: async (slug) => await fetchTopics(dispatch, slug)
  }
}

const mapStateToProps = (state) => {
  return {
    alltopics: state.topicReducer
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllTopics);
