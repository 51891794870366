import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth"

var firebaseConfig = {
    apiKey: "AIzaSyAk-KYJlbpVM-3DcWj4ww0HgRyLpEiFxoc",
    authDomain: "setter-34453.firebaseapp.com",
    databaseURL: "https://setter-34453.firebaseio.com",
    projectId: "setter-34453",
    storageBucket: "setter-34453.appspot.com",
    messagingSenderId: "758809470086",
    appId: "1:758809470086:web:99973cd13457d8d9fb1d07",
    measurementId: "G-JC8WG9Z9X0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

 


  export default firebase;
 